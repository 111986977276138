import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import sectionStyles from './index.module.css';

import rightBackgroundImage from '../../../../static/assets/img/image/m3/M3-07-image-1.png';

class DetailPOSSection extends React.Component {
  render() {
    return (
      <section className={`${sectionStyles.pageSection} section`} data-anchor="POSSection">
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item lg={7} xs={12}>
            <Grid container spacing={1} justify="center" className="articleTitleWithNumberContainerCenter">
              <Grid>
                <div className={`${sectionStyles.articleTitleNumber} articleTitleNumber`}>
                  05
                </div>
              </Grid>
              <Grid>
                <Hidden mdDown>
                  <div className="articleTitleNumberSubTitle">
                    Introduction to M3
                  </div>
                </Hidden>
                <div className={`${sectionStyles.sectionTitleContainer} float-left`} style={{ marginTop: 15 }}>
                  <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-small`}>
                    POS
                  </div>
                  <div className={`sectionTitleUnderline ul-thin`} />
                  <div className={`${sectionStyles.articleTitle} articleTitle font-small-menu`}>
                    Integrated POS allows you to track each purchase your members have made and saves it on their Member Profile.
                  </div>
                  <p className={`${sectionStyles.generalArticle} generalArticle font-small font-narrow`}>
                    Process from your desktop computer, laptop, chrome book, tablet or iPad for VISA Debit, Master Card Debit and credit cards (VISA, M/C, AMEX)
                    <br />
                    Review every P.O.S. transaction history by month.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default DetailPOSSection;