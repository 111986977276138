import React from 'react';
import Grid from '@material-ui/core/Grid';
import sectionStyles from './index.module.css';

class BusinessSpecificSection extends React.Component {

  componentDidMount() {
    window.dispatchEvent(new Event("hideHeaderBg"));
  }
  
  render() {
    const { businessType } = this.props;
    return (
      <section
        style={{backgroundImage:`url(${businessType.background})`}}
        className={`${sectionStyles.pageSection} section`}
        data-anchor="MainSection"
      >
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item xs={false} lg={2} xl={3} />
          <Grid item xs={12} lg={8} xl={6}>
            <div className={`${sectionStyles.articleContainer}`}>
              <div className={`${sectionStyles.sectionTitle} sectionTitle`}>M3 Solutions for {businessType.name}</div>
              <div className={`${sectionStyles.sectionTitleUnderliner} sectionTitleUnderline ul-center`} />
              <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-medium`}>
                M3 Membership Management
                <br />
                is an easy to use web-based
                <br />
                platform that saves
                <br />
                <span>Time, Money and Paper.</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={false} lg={2} xl={3} />
        </Grid>
      </section>
    );
  }
}

export default BusinessSpecificSection;