import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import sectionStyles from './index.module.css';

class DetailReportSection extends React.Component {
  render() {
    return (
      <section className={`${sectionStyles.pageSection} section`} data-anchor="ReportSection">
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item lg={7} xs={12}>
            <Grid container spacing={1} justify="center" className="articleTitleWithNumberContainerCenter">
              <Grid>
                <div className={`${sectionStyles.articleTitleNumber} articleTitleNumber`}>
                  08
                </div>
              </Grid>
              <Grid>
                <Hidden mdDown>
                  <div className="articleTitleNumberSubTitle">
                    Introduction to M3
                  </div>
                </Hidden>
                <div className={`${sectionStyles.sectionTitleContainer} float-left`} style={{ marginTop: 15 }}>
                  <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-small`}>
                    Reports
                  </div>
                  <div className={`sectionTitleUnderline ul-thin`} />
                  <div className={`${sectionStyles.articleTitle} articleTitle font-small`}>
                    Run reports for monthly sales, attendance, payment status and membership expiry's.
                    </div>
                  <p className={`${sectionStyles.generalArticle} generalArticle font-small font-narrow`} style={{ marginTop: 15 }}>
                    Receive automated monthly reports emailed to you listing this months' expiring memberships and credit cards.<br />
                    Monthly Memership Payment Reports can be customized to see the information you want to see. Filter by payment type, member, payment status, program or tag.<br />
                    Run reports for Personal Trainers to see how many sessions each trainer ran each month for each of their programs and see how many resulted in no-show appointments.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default DetailReportSection;