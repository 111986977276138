import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import sectionStyles from './index.module.css';

class IntroSection extends React.Component {
  render() {
    return (
      <section className={`${sectionStyles.pageSection} section`}>
        <div className={`${sectionStyles.partialBackground}`} />
        <Grid container spacing={0} alignItems="center" className={`${sectionStyles.contentContainer} contentContainer`}>
          <Hidden lgUp>
            <Grid item xs={12}>
              <div className={`${sectionStyles.businessTypeArticleContainerMobile}`}>
                <div className={`sectionTitle`}>Business Type</div>
                <div className={`sectionTitleUnderline`} />
                <div className={`articleBigTitle`}>
                  Select Your Business Type
                </div>
              </div>
            </Grid>
          </Hidden>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={5} className={`${sectionStyles.businessTypesContainer}`}>
              {
                this.props.businessTypes.map((businessType, index) => {
                  return (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                      <Button
                        className={`${sectionStyles.businessTypeButton}`}
                        onClick={() => this.props.onBusinessTypeSelect(businessType)}
                      >
                        <img className={`${sectionStyles.businessTypeImg}`} src={businessType.icon} alt="" />
                        <img className={`${sectionStyles.businessTypeImgHover}`} src={businessType.iconHover} alt="" />
                      </Button>
                      <div className={`${sectionStyles.businessTypeName}`}>{businessType.name}</div>
                    </Grid>
                  );
                })
              }
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid item lg={4}>
              <div className={`${sectionStyles.businessTypeArticleContainer}`}>
                <div className={`sectionTitle`}>Business Type</div>
                <div className={`sectionTitleUnderline`} />
                <div className={`articleBigTitle`}>
                  Select Your Business Type
                </div>
                <p className={`articleTitle font-small`}>
                  M3 offers the right fit for you.
                </p>
                <p className={`generalArticle`}>
                  Fitness, Martial Arts, Personal Trainer, Yoga/Filates, Dance, Musice, Art School, Math/Language School, After School Program
                </p>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </section>
    );
  }
}

export default IntroSection;