import React from 'react';
import Grid from '@material-ui/core/Grid';

import sectionStyles from './index.module.css';

class DetailsMenu extends React.Component {
  render() {
    return (
      <section className={`${sectionStyles.pageSection} fullPageDialogSection`}>
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item lg={6} xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <a
                  className={`${sectionStyles.menuLink}`}
                  href="#AutoPaySection"
                  onClick={() => {this.props.menuSelectCallback()}}
                >
                  <div className="articleTitleWithNumberContainer">
                    <div className={`articleTitleNumber float-left`}>
                      01
                    </div>
                    <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-menu float-left`}>
                      Automated Payment & Tracking
                    </div>
                  </div>
                </a>
              </Grid>
              <Grid item xs={12}>
                <a
                  className={`${sectionStyles.menuLink}`}
                  href="#MemberManageSection"
                  onClick={() => {this.props.menuSelectCallback();}}
                >
                  <div className="articleTitleWithNumberContainer">
                    <div className={`articleTitleNumber float-left`}>
                      02
                    </div>
                    <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-menu float-left`}>
                      Membership Management
                    </div>
                  </div>
                </a>
              </Grid>
              <Grid item xs={12}>
                <a
                  className={`${sectionStyles.menuLink}`}
                  href="#AttendanceSection"
                  onClick={() => {this.props.menuSelectCallback();}}
                >
                  <div className="articleTitleWithNumberContainer">
                    <div className={`articleTitleNumber float-left`}>
                      03
                    </div>
                    <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-menu float-left`}>
                      Attendance Tracking
                    </div>
                  </div>
                </a>
              </Grid>
              <Grid item xs={12}>
                <a
                  className={`${sectionStyles.menuLink}`}
                  href="#FaceCheckInSection"
                  onClick={() => {this.props.menuSelectCallback();}}
                >
                  <div className="articleTitleWithNumberContainer">
                    <div className={`articleTitleNumber float-left`}>
                      04
                    </div>
                    <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-small float-left`}>
                      Face Id Check-In
                    </div>
                  </div>
                </a>
              </Grid>
              <Grid item xs={12}>
                <a
                  className={`${sectionStyles.menuLink}`}
                  href="#POSSection"
                  onClick={() => {this.props.menuSelectCallback();}}
                >
                  <div className="articleTitleWithNumberContainer">
                    <div className={`articleTitleNumber float-left`}>
                      05
                    </div>
                    <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-menu float-left`}>
                      POS
                    </div>
                  </div>
                </a>
              </Grid>
              <Grid item xs={12}>
                <a
                  className={`${sectionStyles.menuLink}`}
                  href="#SMSSection"
                  onClick={() => {this.props.menuSelectCallback();}}
                >
                  <div className="articleTitleWithNumberContainer">
                    <div className={`articleTitleNumber float-left`}>
                      06
                    </div>
                    <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-menu float-left`}>
                      SMS / Email
                    </div>
                  </div>
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <a
                  className={`${sectionStyles.menuLink}`}
                  href="#TrackingSection"
                  onClick={() => {this.props.menuSelectCallback();}}
                >
                  <div className="articleTitleWithNumberContainer">
                    <div className={`articleTitleNumber float-left`}>
                      07
                    </div>
                    <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-menu float-left`}>
                      Class, Test & Program Tracking
                    </div>
                  </div>
                </a>
              </Grid>
              <Grid item xs={12}>
                <a
                  className={`${sectionStyles.menuLink}`}
                  href="#ReportSection"
                  onClick={() => {this.props.menuSelectCallback();}}
                >
                  <div className="articleTitleWithNumberContainer">
                    <div className={`articleTitleNumber float-left`}>
                      08
                    </div>
                    <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-menu float-left`}>
                      Reports
                    </div>
                  </div>
                </a>
              </Grid>
              <Grid item xs={12}>
                <a
                  className={`${sectionStyles.menuLink}`}
                  href="#RewardSection"
                  onClick={() => {this.props.menuSelectCallback();}}
                >
                  <div className="articleTitleWithNumberContainer">
                    <div className={`articleTitleNumber float-left`}>
                      09
                    </div>
                    <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-menu float-left`}>
                      Reward Point System
                    </div>
                  </div>
                </a>
              </Grid>
              <Grid item xs={12}>
                <a
                  className={`${sectionStyles.menuLink}`}
                  href="#StaffSection"
                  onClick={() => {this.props.menuSelectCallback();}}
                >
                  <div className="articleTitleWithNumberContainer">
                    <div className={`articleTitleNumber float-left`}>
                      10
                    </div>
                    <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-menu float-left`}>
                      Staff Accounts
                    </div>
                  </div>
                </a>
              </Grid>
              <Grid item xs={12}>
                <a
                  className={`${sectionStyles.menuLink}`}
                  href="#DashboardSection"
                  onClick={() => {this.props.menuSelectCallback();}}
                >
                  <div className="articleTitleWithNumberContainer">
                    <div className={`articleTitleNumber float-left`}>
                      11
                    </div>
                    <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-menu float-left`}>
                      Dashboard Calendar
                    </div>
                  </div>
                </a>
              </Grid>
              <Grid item xs={12}>
                <a
                  className={`${sectionStyles.menuLink}`}
                  href="#LeadSection"
                  onClick={() => {this.props.menuSelectCallback();}}
                >
                  <div className="articleTitleWithNumberContainer">
                    <div className={`articleTitleNumber float-left`}>
                      12
                    </div>
                    <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-menu float-left`}>
                      Lead Generation
                    </div>
                  </div>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default DetailsMenu;