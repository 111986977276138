import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import sectionStyles from './index.module.css';

import rightBackgroundImage from '../../../../static/assets/img/image/m3/M3-07-image-1.png';

class DetailDashboardSection extends React.Component {
  render() {
    return (
      <section className={`${sectionStyles.pageSection} section`} data-anchor="DashbaordSection">
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item lg={7} xs={12}>
            <Grid container spacing={1} justify="center" className="articleTitleWithNumberContainerCenter">
              <Grid>
                <div className={`${sectionStyles.articleTitleNumber} articleTitleNumber`}>
                  11
                </div>
              </Grid>
              <Grid>
                <Hidden mdDown>
                  <div className="articleTitleNumberSubTitle">
                    Introduction to M3
                  </div>
                </Hidden>
                <div className={`${sectionStyles.sectionTitleContainer} float-left`} style={{ marginTop: 15 }}>
                  <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-small`}>
                    Dashboard
                  </div>
                  <div className={`sectionTitleUnderline ul-thin`} />
                  <div className={`${sectionStyles.articleTitle} articleTitle font-small-menu`}>
                    Keep important business information<br />
                    a click away on your dashboard.
                  </div>
                  <p className={`${sectionStyles.generalArticle} generalArticle font-small font-narrow`}>
                    Stay on top of appoinment and event bookings with a shared staff calendar.<br />
                    Download monthly statements from your business profile.<br />
                    Service Agreement easily accessible from your business profile.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default DetailDashboardSection;