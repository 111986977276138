import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import sectionStyles from './index.module.css';

class DetailAutoPaySection extends React.Component {
  render() {
    return (
      <section className={`${sectionStyles.pageSection} section`} data-anchor="AutoPaySection">
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item lg={6} xs={12} >
            <Grid container spacing={1} justify="center" className="articleTitleWithNumberContainerCenter">
              <Grid>
                <div className={`${sectionStyles.articleTitleNumber} articleTitleNumber`}>
                  01
                </div>
              </Grid>
              <Grid>
                <Hidden mdDown>
                  <div className="articleTitleNumberSubTitle">
                    Introduction to M3
                  </div>
                </Hidden>
                <div className={`${sectionStyles.sectionTitleContainer} float-left`} style={{marginTop: 15}}>
                  <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-small`}>
                    Automated<br />Payment &<br />Tracking
                  </div>
                  <div className={`sectionTitleUnderline ul-long ul-thin`} />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12} >
            <div className={`${sectionStyles.generalArticlesContainer}`}>
              <div className={`${sectionStyles.articleTitle} articleTitle font-small`} style={{marginBottom: 30}}>
                Automated Billing Made Simple.
                <br />
                Set it up once and forget about it.
              </div>
              <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
                We process and settle automated payments 6 times a month for EFT (void cheques), Debit/Credit Cards so you get your funds deposited into your account faster.
              </p>
              <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
                Get automatic notifications and alerts for fail payments, expiring memberships and credit cards.
              </p>
              <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
                Check Payment Status/History for all your members with a simple click - even multiple active contracts by the same member.
              </p>
              <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
                Full Collection Services available.
              </p>
            </div>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default DetailAutoPaySection;