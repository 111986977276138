import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import sectionStyles from './index.module.css';

class DetailTrackingSection extends React.Component {
  render() {
    return (
      <section className={`${sectionStyles.pageSection} section`} data-anchor="TrackingSection">
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item lg={7} xs={12}>
            <Grid container spacing={1} justify="center" className="articleTitleWithNumberContainerCenter">
              <Grid>
                <div className={`${sectionStyles.articleTitleNumber} articleTitleNumber`}>
                  07
                </div>
              </Grid>
              <Grid>
                <Hidden mdDown>
                  <div className="articleTitleNumberSubTitle">
                    Introduction to M3
                  </div>
                </Hidden>
                <div className={`${sectionStyles.sectionTitleContainer} float-left`} style={{ marginTop: 15 }}>
                  <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-small`}>
                    Class & Program<br />
                    Tracking
                  </div>
                  <div className={`sectionTitleUnderline ul-thin`} />
                  <div className={`${sectionStyles.articleTitle} articleTitle font-small`}>
                    Add and schedule different class types and programs integrated with the attendance tracking.
                  </div>
                  <p className={`${sectionStyles.generalArticle} generalArticle font-small font-narrow`} style={{ marginTop: 15 }}>
                    Create levels or ranks for each program type.<br />
                    Test History section allows you to easily keep track of each students testing date and progression.<br />
                    Stay on top of which class or programs are more popular. Our system can provide attendance totals by class/program for each month.<br />
                    See a class list of who is registered in a specific class/program.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default DetailTrackingSection;