import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import sectionStyles from './index.module.css';

import clockImage from '../../../../static/assets/img/image/m3/M3-05-image.png';
import clockSmallImage from '../../../../static/assets/img/image/m3/M3-05 small-image.png';

class DetailAttendanceSection extends React.Component {
  render() {
    return (
      <section className={`${sectionStyles.pageSection} section`} data-anchor="AttendanceSection">
        <img className={`${sectionStyles.topLeftClockImage}`} src={clockImage} alt="" />
        <img className={`${sectionStyles.bottomRightClockImage}`} src={clockSmallImage} alt="" />
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item lg={6} xs={12}>
            <Grid container spacing={1} justify="center" className="articleTitleWithNumberContainerCenter">
              <Grid>
                <div className={`${sectionStyles.articleTitleNumber} articleTitleNumber`}>
                  03
                </div>
              </Grid>
              <Grid>
                <Hidden mdDown>
                  <div className="articleTitleNumberSubTitle">
                    Introduction to M3
                  </div>
                </Hidden>
                <div className={`${sectionStyles.sectionTitleContainer} float-left`} style={{ marginTop: 15 }}>
                  <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-small`}>
                    Attendance Tracking
                  </div>
                  <div className={`sectionTitleUnderline ul-long ul-thin`} />
                  <div className={`${sectionStyles.articleTitle} articleTitle font-small`}>
                    What makes our Attendance Tracking program so great is that you can set it up based on how you run your business and sell your memberships.
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12}>
            <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
              Set-up check-in limitations based on contract type for time-based memberships i.e. 2 Day/week membership, Unlimited Membership.
            </p>
            <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
              Allow multiple check-ins per day for class base or package-based memberships and track which classes each member checked into.
            </p>
            <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
              Optional automated check-in notice texted to members with reward point totals.
            </p>
            <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
              Receive failed check-in notice for attempted check-ins for expired memberships or if a member has exceeded the number of check-ins for the week.
            </p>
            <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
              See your attendance totals by day and month for each program/class. <br />
              See who checked in each day and into which class/program.
            </p>
            <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
              For Package based memberships track no-shows and minus the session from their package.<br />
              For package-based memberships, track check-ins and no-shows by trainer.
            </p>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default DetailAttendanceSection;