import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import sectionStyles from './index.module.css';

class DetailLeadGenerationSection extends React.Component {
	render() {
		return (
			<section className={`${sectionStyles.pageSection} section`} data-anchor="LeadSection">
				<Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
					<Grid lg={6} xs={12} className={`${sectionStyles.contentContainer} contentContainer`} style={{ marginTop: 120 }}>
						<Grid container spacing={1} justify="center" className="articleTitleWithNumberContainerCenter">
							<Grid lg={1}>
								<div className={`${sectionStyles.articleTitleNumber} articleTitleNumber`}>
									12
                </div>
							</Grid>
							<Grid lg={7}>
								<Hidden mdDown>
									<div className="articleTitleNumberSubTitle">
										Introduction to M3
                  </div>
								</Hidden>
								<div className={`${sectionStyles.sectionTitleContainer} float-left`} style={{ marginTop: 15 }}>
									<div className={`${sectionStyles.articleBigTitle} articleBigTitle font-small`}>
										Lead Generation
                  </div>
									<div className={`sectionTitleUnderline ul-long ul-thin`} />
									<div className={`${sectionStyles.articleTitle} articleTitle font-small`}>
										Collect contact information from<br />
										customers visiting your website.
                  </div>
									<p className={`${sectionStyles.generalArticle} generalArticle font-small font-narrow`} style={{ marginTop: 20 }}>
										Our Lead Generating Form is integrated with M3 platform so you can send SMS/Email messages and better track your prospect customers.<br />
										This option is available as an add-on with your M3 subscription.
                  </p>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</section>
		);
	}
}

export default DetailLeadGenerationSection;