import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import sectionStyles from './index.module.css';

class DetailSMSSection extends React.Component {
	render() {
		return (
			<section className={`${sectionStyles.pageSection} section`} data-anchor="SMSSection">
				<Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
					<Grid lg={6} xs={12} className={`${sectionStyles.contentContainer} contentContainer`} style={{ marginTop: 120 }}>
						<Grid container spacing={1} justify="center" className="articleTitleWithNumberContainerCenter">
							<Grid lg={1}>
								<div className={`${sectionStyles.articleTitleNumber} articleTitleNumber`}>
									06
                </div>
							</Grid>
							<Grid lg={7}>
								<Hidden mdDown>
									<div className="articleTitleNumberSubTitle">
										Introduction to M3
                  </div>
								</Hidden>
								<div className={`${sectionStyles.sectionTitleContainer} float-left`} style={{ marginTop: 15 }}>
									<div className={`${sectionStyles.articleBigTitle} articleBigTitle font-small`}>
										SMS Text / E-Mail <br />
										Messaging
                  </div>
									<div className={`sectionTitleUnderline ul-long ul-thin`} />
									<div className={`${sectionStyles.articleTitle} articleTitle font-small`}>
										You control what the message is and who it is sent to. Send notices, alerts and reminders to your embers directly from our platform.
                  </div>
									<p className={`${sectionStyles.generalArticle} generalArticle font-small font-narrow`} style={{ marginTop: 20 }}>
										SMS text message and email messaging options available directly from the platform search results. Use this to send out reminders, announcements or advertise special promotions or events.
                  </p>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</section>
		);
	}
}

export default DetailSMSSection;