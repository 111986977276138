import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import sectionStyles from './index.module.css';

class DetailRewardSection extends React.Component {
  render() {
    return (
      <section className={`${sectionStyles.pageSection} section`} data-anchor="RewardSection">
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item md={6} xs={12}>
            <Grid container spacing={1} justify="center" className="articleTitleWithNumberContainerCenter">
              <Grid>
                <div className={`${sectionStyles.articleTitleNumber} articleTitleNumber`}>
                  09
                </div>
              </Grid>
              <Grid>
                <Hidden mdDown>
                  <div className={`${sectionStyles.articleTitleNumberSubTitle} articleTitleNumberSubTitle`}>
                    Introduction to M3
                  </div>
                </Hidden>
                <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-small`}>
                  Reward Point <br />
                  System
                </div>
                <div className={`${sectionStyles.sectionTitleUnderline} sectionTitleUnderline ul-thin`} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={5} xs={12}>
            <div className={`${sectionStyles.generalArticleContainer}`}>
              <div className={`${sectionStyles.articleTitle} articleTitle font-small`}>
                Encourage member retention, member loyalty and referrals with a reward point system.
              </div>
              <p className={`${sectionStyles.generalArticle} generalArticle font-small font-narrow`} style={{ marginTop: 15, color: '#CBCDCE' }}>
                Reward point system included at no additional charge.<br />
                Easy opt-in/opt-out for your members.<br />
                Set-up reward points and prizing to suit your business i.e. points issued for attendance, good effort or referrals received.<br />
                Automated reward points issued and tracked with each checking. Add bonus points directly on a members' profile.<br />
                Optional SMS Text notification with points earned sent with each check-in.
              </p>
            </div>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default DetailRewardSection;