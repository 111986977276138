import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import sectionStyles from './index.module.css';

import holdingHandsImage from '../../../../static/assets/img/image/m3/M3-04-image.png';

class DetailMemberManageSection extends React.Component {
  render() {
    return (
      <section className={`${sectionStyles.pageSection} section`} data-anchor="MemberManageSection">
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item lg={6} xs={12} className={`${sectionStyles.sectionTitleContainerGrid}`}>
            <Grid container spacing={1} justify="center" className="articleTitleWithNumberContainerCenter">
              <Grid>
                <div className={`${sectionStyles.articleTitleNumber} articleTitleNumber`}>
                  02
                </div>
              </Grid>
              <Grid>
                <Hidden mdDown>
                  <div className="articleTitleNumberSubTitle">
                    Introduction to M3
                  </div>
                </Hidden>
                <div className={`${sectionStyles.sectionTitleContainer} float-left`} style={{marginTop: 15}}>
                  <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-small`}>
                    Membership Management
                  </div>
                  <div className={`sectionTitleUnderline ul-long ul-thin`} />
                  <div className={`${sectionStyles.articleTitle} articleTitle font-small`}>
                    Membership Management Made Easy. Keep your members contact, membership and payment info all in one place.
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12}>
            <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
              Easily set-up and update your Memberships.
            </p>
            <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
              Make changes to membership terms, payment schedule and even set-up multiple contracts for the same member or payer.
            </p>
            <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
              Get early notifications of expiring contracts and credit cards.
            </p>
            <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
              Set-up and Track Membership based on how you sell your memberships, either Time based Memberships, Class Based Memberships or Package Based Memberships.
            </p>
            <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
              Send Text/E-mail messages to your members directly from our platform. You control the message and who it is sent to.
            </p>
            <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
              Keep track of your members purchase history and payment schedule saved on their member profile.
            </p>
            <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
              Keep track of your members programs progress using the Belt/Test Recorder.
            </p>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default DetailMemberManageSection;