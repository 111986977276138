import React from 'react';

import IntroSection from './Intro';
import BusinessSpecificSection from './BusinessSpecific';

import bgFitness from '../../../../static/assets/img/background/m3/M3-title2.png';
import bgMartialArts from '../../../../static/assets/img/background/m3/M3-title3.png';
import bgPersonalTrainer from '../../../../static/assets/img/background/m3/M3-title4.png';
import bgYoga from '../../../../static/assets/img/background/m3/M3-title5.png';
import bgDance from '../../../../static/assets/img/background/m3/M3-title7.png';
import bgMusic from '../../../../static/assets/img/background/m3/M3-title6.png';
import bgArt from '../../../../static/assets/img/background/m3/M3-title8.png';
import bgLanguage from '../../../../static/assets/img/background/m3/M3-title9.png';
import bgAfterSchool from '../../../../static/assets/img/background/m3/M3-title10.png';

import menuFitness from '../../../../static/assets/img/icon/m3/menu-Fitness-1.png';
import menuFitnessHover from '../../../../static/assets/img/icon/m3/menu-Fitness.png';
import menuMartialArts from '../../../../static/assets/img/icon/m3/menu-Martial Arts-1.png';
import menuMartialArtsHover from '../../../../static/assets/img/icon/m3/menu-Martial Arts.png';
import menuPersonalTrainer from '../../../../static/assets/img/icon/m3/menu-Personal Trainer-1.png';
import menuPersonalTrainerHover from '../../../../static/assets/img/icon/m3/menu-Personal Trainer.png';
import menuYoga from '../../../../static/assets/img/icon/m3/menu-Yoga-1.png';
import menuYogaHover from '../../../../static/assets/img/icon/m3/menu-Yoga.png';
import menuDance from '../../../../static/assets/img/icon/m3/menu-Dance-1.png';
import menuDanceHover from '../../../../static/assets/img/icon/m3/menu-Dance.png';
import menuMusic from '../../../../static/assets/img/icon/m3/menu-Music-1.png';
import menuMusicHover from '../../../../static/assets/img/icon/m3/menu-Music.png';
import menuArt from '../../../../static/assets/img/icon/m3/menu-Art-1.png';
import menuArtHover from '../../../../static/assets/img/icon/m3/menu-Art.png';
import menuLanguage from '../../../../static/assets/img/icon/m3/menu-Language-1.png';
import menuLanguageHover from '../../../../static/assets/img/icon/m3/menu-Language.png';
import menuAfterSchool from '../../../../static/assets/img/icon/m3/menu-After School-1.png';
import menuAfterSchoolHover from '../../../../static/assets/img/icon/m3/menu-After School.png';

let businessTypes = [
  {name: 'Fitness / Gym', background: bgFitness, icon: menuFitness, iconHover: menuFitnessHover},
  {name: 'Martial Arts', background: bgMartialArts, icon: menuMartialArts, iconHover: menuMartialArtsHover},
  {name: 'Personal Trainer', background: bgPersonalTrainer, icon: menuPersonalTrainer, iconHover: menuPersonalTrainerHover},
  {name: 'Yoga / Pilates', background: bgYoga, icon: menuYoga, iconHover: menuYogaHover},
  {name: 'Dance School', background: bgDance, icon: menuDance, iconHover: menuDanceHover},
  {name: 'Music School', background: bgMusic, icon: menuMusic, iconHover: menuMusicHover},
  {name: 'Art School', background: bgArt, icon: menuArt, iconHover: menuArtHover},
  {name: 'Math / Language School', background: bgLanguage, icon: menuLanguage, iconHover: menuLanguageHover},
  {name: 'After School Program', background: bgAfterSchool, icon: menuAfterSchool, iconHover: menuAfterSchoolHover}
]

class MainSection extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      selectedBusinessType: undefined
    };
  }

  onBusinessTypeSelect = (businessType) => {
    this.setState({ selectedBusinessType: businessType }, this.props.businessTypeSelectedCallBack);
  };

  render() {
    const { selectedBusinessType } = this.state;
    return (
      <React.Fragment>
        {
          (!selectedBusinessType) ?
          <IntroSection
            businessTypes={businessTypes}
            onBusinessTypeSelect={this.onBusinessTypeSelect}
          /> :
          <BusinessSpecificSection
            businessType={selectedBusinessType}
          />
        }
        
      </React.Fragment>
    );
  }
}

export default MainSection;