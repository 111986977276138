import React from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import sectionStyles from './index.module.css';

class DetailFaceCheckInSection extends React.Component {
  render() {
    return (
      <section className={`${sectionStyles.pageSection} section`} data-anchor="FaceCheckInSection">
        <Grid container spacing={0} className={`${sectionStyles.contentContainer} contentContainer`}>
          <Grid item md={6} xs={12}>
            <Grid container spacing={1} justify="center" className="articleTitleWithNumberContainerCenter">
              <Grid>
                <div className={`${sectionStyles.articleTitleNumber} articleTitleNumber`}>
                  04
                </div>
              </Grid>
              <Grid>
                <Hidden mdDown>
                  <div className="articleTitleNumberSubTitle">
                    Introduction to M3
                  </div>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={5} xs={12}>
            <div className={`${sectionStyles.generalArticleContainer}`}>
              <div>
                <div className={`${sectionStyles.articleBigTitle} articleBigTitle font-small`}>
                  Face ID Check-In
                </div>
                <div className={`${sectionStyles.sectionTitleUnderline} sectionTitleUnderline ul-long ul-thin`} />
                <div className={`${sectionStyles.articleTitle} articleTitle font-small`}>
                  Ditch the membership cards/tags and quickly scan in members using Face Recognition Technology.
                </div>
              </div>
              <div style={{marginTop: 30}}>
                <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
                  Can be set-up as an independent check-in kiosk or at your reception desk. Works with PC, MAC desktop, laptop, Chrome Book, iPad or Android Tablet.
                </p>
                <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
                  Members scan in with a webcam or tablet, which matches their scan to their profile picture. Check-in by phone number option also available.
                </p>
                <p className={`${sectionStyles.generalArticle} generalArticle font-narrow`}>
                  Instant SMS Text message option available for each check-in. Great for After School Programs.
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
    );
  }
}

export default DetailFaceCheckInSection;