import React from 'react';
import Layout from '../components/Layout';
import pageStyles from '../styles/m3.module.css';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import ReactFullpage from '@fullpage/react-fullpage';
import {
  MainSection,
  DetailAutoPaySection,
  DetailMemberManageSection,
  DetailAttendanceSection,
  DetailFaceCheckInSection,
  DetailPOSSection,
  DetailSMSSection,
  DetailTrackingSection,
  DetailReportSection,
  DetailRewardSection,
  DetailStaffSection,
  DetailLeadGenerationSection,
  DetailDashboardSection,
  DetailsMenu
} from '../components/M3';
import { graphql } from 'gatsby';
import ContactMainSection from '../components/Contact/Main';
import Footer from '../components/Footer';
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"


const pluginWrapper = () => {
  require('fullpage.js/vendors/scrolloverflow');
};

const timeout = 1000;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} timeout={1500} />;
});

class M3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBusinessTypeSelected: false,
      openDialog: false
    };
  }

  businessTypeSelectedCallBack = () => {
    this.setState({ isBusinessTypeSelected: true });
  }

  toggleDialog = () => () => {
    this.setState({
      openDialog: !this.state.openDialog
    });
  }

  render() {
    const { data } = this.props;
    const { isBusinessTypeSelected } = this.state;
    return (
      <Layout location={this.props.location} title="M3">
        <head>
          <title>{`M3 | ${data.site.siteMetadata.title}`}</title>
          <link rel="canonical" href={`${data.site.siteMetadata.url}/m3/`} />
        </head>
        <TransitionGroup>
          <ReactTransition
            key={'/m3'}
            timeout={{
              enter: timeout,
              exit: timeout,
            }}
          >
            <div>
              <ReactFullpage
                pluginWrapper={pluginWrapper}
                scrollOverflow
                licenseKey={'AA6575F4-62CE406F-B83ACFDF-DADAA912'}
                verticalCentered={false}
                onLeave={(index, nextIndex, direction) => {
                  window.dispatchEvent(new Event(nextIndex.index ? "showHeaderBg" : "hideHeaderBg"))
                }}
                render={({ state, fullpageApi }) => {
                  return (
                    <ReactFullpage.Wrapper>
                      <MainSection businessTypeSelectedCallBack={this.businessTypeSelectedCallBack} />
                      {
                        (isBusinessTypeSelected) ?
                          <React.Fragment>
                            <DetailAutoPaySection />
                            <DetailMemberManageSection />
                            <DetailAttendanceSection />
                            <DetailFaceCheckInSection />
                            <DetailPOSSection />
                            <DetailSMSSection />
                            <DetailTrackingSection />
                            <DetailReportSection />
                            <DetailRewardSection />
                            <DetailStaffSection />
                            <DetailDashboardSection />
                            <DetailLeadGenerationSection />
                            <ContactMainSection />
                          </React.Fragment> :
                          null
                      }
                    </ReactFullpage.Wrapper>
                  );
                }}
              />
              {
                (isBusinessTypeSelected) ?
                  <Fab
                    className={`${pageStyles.m3DetailsMenuButton}`}
                    color="primary" aria-label="M3DetailsMenuButton"
                    onClick={this.toggleDialog()}
                  >
                    <ChevronLeftIcon fontSize="large" />
                  </Fab> :
                  null
              }
              <Dialog
                fullScreen
                open={this.state.openDialog}
                onClose={this.toggleDialog()}
                TransitionComponent={Transition}
                className={`${pageStyles.m3DetailsMenuDialog}`}
              >
                <Fab
                  className={`${pageStyles.m3DetailsMenuButton}`}
                  color="secondary" aria-label="M3DetailsMenuCloseButton"
                  onClick={this.toggleDialog()}
                >
                  <CloseIcon fontSize="large" />
                </Fab>
                <DetailsMenu
                  menuSelectCallback={this.toggleDialog()}
                />
              </Dialog>
            </div>
          </ReactTransition>
        </TransitionGroup>
      </Layout>
    );
  }
}

export default M3;

export const query = graphql`
  query M3PageQuery {
    site {
      siteMetadata {
        title
        url
      }
    }
  }
`;
